import React, { ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';
import ThemeContext from '../../store/ThemeContext';

interface HeaderProps {
  openInfoModal: (state: boolean) => void;
  openStatsModal: (state: boolean) => void;
  openHintModal: (state: boolean) => void;
}

const Header = ({
  openInfoModal,
  openStatsModal,
  openHintModal,
}: HeaderProps): ReactElement => {
  const theme = useContext(ThemeContext);
  const [buttonHover, setButtonHover] = useState(false);
  const history = useHistory();

  return (
    <header
      className="headerContainer"
      style={{ borderBottom: `solid 1px ${theme.backgroundColorFaint}` }}
    >
      <section className="helpSection">
        <ArrowBackIosNewIcon
          onClick={() => history.push('/')}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              openHintModal(true);
            }
          }}
        />
        <Button
          variant="contained"
          className="hintButton"
          type="button"
          onClick={() => openHintModal(true)}
          endIcon={<LightbulbIcon />}
          tabIndex={0}
          onMouseEnter={() => {
            setButtonHover(true);
          }}
          onMouseLeave={() => {
            setButtonHover(false);
          }}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              openHintModal(true);
            }
          }}
          style={{
            background: buttonHover
              ? theme.backgroundColorFaint
              : theme.backgroundColorDark,
          }}
        >
          Hint
        </Button>
      </section>
      <h1>
        <span style={{ color: theme.TeamColor }}>
          #{theme.TeamAbbreviation.toUpperCase()}
        </span>
        LE
      </h1>
      <section className="statsSection">
        <HelpIcon
          onClick={() => openInfoModal(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              openHintModal(true);
            }
          }}
        />
        <ShowChartIcon
          onClick={() => openStatsModal(true)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              openStatsModal(true);
            }
          }}
        />
      </section>
    </header>
  );
};
export default Header;
