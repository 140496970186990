import { Seasons, Teams, Positions, PlayerInfoType } from '../../types/player';

const HullPlayers: PlayerInfoType[] = [
  {
    firstName: 'Harvey',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Cartwright',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Billy',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Leach',
    team: Teams.U18s,
  },
  {
    firstName: 'Louis',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Beckett',
    team: Teams.U23s,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Cannon',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Allahyar',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Sayyadmanesh',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Regan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Slater',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Festus',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Arthur',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Richard',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Smallwood',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Greg',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Docherty',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nathan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Baxter',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mallik',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Wilks',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'George',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Moncur',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ahmed',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Salam',
    team: Teams.U23s,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Nixon',
    team: Teams.U23s,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Longman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Robson',
    team: Teams.U23s,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Huddlestone',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jacob',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Greaves',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Hinds',
    team: Teams.U23s,
  },
  {
    firstName: 'George',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Honeyman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Billy',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Chadwich',
    team: Teams.U23s,
  },
  {
    firstName: 'Matty',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Jacob',
    team: Teams.U23s,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Wood',
    team: Teams.U23s,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Leake',
    team: Teams.U23s,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Elder',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Keane',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Lewis-Potter',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Oliver',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Green',
    team: Teams.U23s,
  },
  {
    firstName: 'Marcus',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Forss',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brandon',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Fleming',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andy',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Smith',
    team: Teams.U23s,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Jones',
    team: Teams.U23s,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Fisk',
    team: Teams.U23s,
  },
  {
    firstName: 'MaCauley',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Snelgrove',
    team: Teams.U23s,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Lovick',
    team: Teams.U23s,
  },
  {
    firstName: 'Alfie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tyler',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Smith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Walsh',
    team: Teams.FirstTeam,
  },
  {
    firstName: "Di'Shon",
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bernard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Will',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Jarvis',
    team: Teams.U23s,
  },
  {
    firstName: 'Matt',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Ingram',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Coyle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Randell',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joshua',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Emmanuel',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sean',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'McLoughlin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Eaves',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Scott',
    team: Teams.FirstTeam,
  },
];
export default HullPlayers;
