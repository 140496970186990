export enum Teams {
  FirstTeam = 'First Team',
  U23s = 'Under 23s',
  U18s = 'Under 18s',
}

export enum Seasons {
  TwentyOneTwentyTwo = '2021 - 2022',
  TwentyTwentyTwentyOne = '2020 - 2021',
  NineteenTwenty = '2019 - 2020',
  EighteenNineteen = '2018 - 2019',
  SeventeenEighteen = '2017 - 2018',
  SixteenSeventeen = '2016 - 2017',
  FifteenSixteen = '2015 - 2016',
  FourteenFifteen = '2014 - 2015',
  ThirteenFourteen = '2013 - 2014',
  TwelveThirteen = '2012 - 2013',
  ElevenTwelve = '2011 - 2012',
  TenEleven = '2010 - 2011',
  NineTen = '2009 - 2010',
  EightNine = '2008 - 2009',
  SevenEight = '2007 - 2008',
  SixSeven = '2006 - 2007',
  FiveSix = '2005 - 2006',
  FourFive = '2004 - 2005',
  ThreeFour = '2003 - 2004',
  TwoThree = '2002 - 2003',
  OneTwo = '2001 - 2002',
  TwoThousandTwoThousandTwo = '2000 - 2001',
}

export enum Positions {
  Goalkeeper = 'Goalkeeper',
  Defender = 'Defender',
  Midfielder = 'Midfielder',
  Forward = 'Forward',
}

export interface PlayerInfoType {
  firstName: string;
  secondName: string;
  team: Teams;
  position: Positions;
  mostRecentYear: Seasons;
}
