import React, { ReactElement } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Pages
import Homepage from '../components/Homepage/Homepage';
import Container from '../components/Container/Container';

const Routes = (): ReactElement => (
  <Switch>
    <Route exact path="/">
      <Homepage />
    </Route>
    <Route path="/:slug">
      <Container />
    </Route>
    <Redirect to="/" />
  </Switch>
);
export default Routes;
