import { AvailableWordlesType } from '../constants/availableWordles';

type StoredGameState = {
  guesses: string[];
  solution: string;
  lastActive: string;
};

export const saveGameStateToLocalStorage = (
  gameState: StoredGameState,
  theme: AvailableWordlesType
): void => {
  localStorage.setItem(
    `${theme.TeamAbbreviation.toLowerCase()}leState`,
    JSON.stringify(gameState)
  );
};

export const loadGameStateFromLocalStorage = (
  theme: AvailableWordlesType
): StoredGameState | null => {
  const state = localStorage.getItem(
    `${theme.TeamAbbreviation.toLowerCase()}leState`
  );
  return state ? (JSON.parse(state) as StoredGameState) : null;
};

export type GameStats = {
  winDistribution: number[];
  gamesFailed: number;
  currentStreak: number;
  bestStreak: number;
  totalGames: number;
  successRate: number;
};

export const saveStatsToLocalStorage = (
  gameStats: GameStats,
  theme: AvailableWordlesType
): void => {
  localStorage.setItem(
    `${theme.TeamAbbreviation.toLowerCase()}leStats`,
    JSON.stringify(gameStats)
  );
};

export const loadStatsFromLocalStorage = (
  theme: AvailableWordlesType
): GameStats | null => {
  const stats = localStorage.getItem(
    `${theme.TeamAbbreviation.toLowerCase()}leStats`
  );
  return stats ? (JSON.parse(stats) as GameStats) : null;
};
