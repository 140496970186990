export interface AvailableWordlesType {
  StartDate: string;
  Team: string;
  TeamAbbreviation: string;
  TotalTries: string;
  SuccessRate: string;
  CurrentStreak: string;
  BestStreak: string;
  TeamColor: string;
  CelebrationMessage: string;
  OldestSeason: string;
  GameURL: string | null;
  slug: string;
  backgroundColor: string;
  backgroundColorDark: string;
  backgroundColorTranslucent: string;
  backgroundColorFaint: string;
  textColor: string;
  textColorDark: string;
  correctKeyColor: string;
  correctKeyColorDark: string;
  presentKeyColor: string;
  presentKeyColorDark: string;
  absentKeyColor: string;
  absentKeyColorDark: string;
  shareButtonColor: string;
  socialHashtag?: string;
}

const availableWordles: AvailableWordlesType[] = [
  {
    StartDate: 'February 2, 2022 00:00:00',
    Team: 'Football Wordle',
    TeamAbbreviation: 'FBWL',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#91c7ff',
    CelebrationMessage: 'Default Message!',
    OldestSeason: '2021/2022',
    GameURL: 'https://wafcle.com',
    slug: '',
    backgroundColor: '#091021',
    backgroundColorDark: '#091021',
    backgroundColorTranslucent: '#0f172a70',
    backgroundColorFaint: '#1a253d',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#1f3ad1',
  },
  {
    StartDate: 'February 2, 2022 00:00:00',
    Team: 'Wigan Athletic',
    TeamAbbreviation: 'WAFC',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#91c7ff',
    CelebrationMessage: 'Up the tics!',
    OldestSeason: '2016/2017',
    GameURL: 'https://wafcle.netlify.app',
    slug: 'WAFC',
    backgroundColor: '#091021',
    backgroundColorDark: '#091021',
    backgroundColorTranslucent: '#0f172a70',
    backgroundColorFaint: '#1a253d',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#1f3ad1',
  },
  {
    StartDate: 'February 4, 2022 00:00:00',
    Team: 'Luton Town',
    TeamAbbreviation: 'LTFC',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#f47830',
    CelebrationMessage: 'Up the Hatters!',
    OldestSeason: '2021/2022',
    GameURL: 'https://ltfcle.netlify.app/',
    slug: 'LTFC',
    backgroundColor: '#200c01',
    backgroundColorDark: '#250e01',
    backgroundColorTranslucent: '#3b160070',
    backgroundColorFaint: '#55290f',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#f47830',
  },
  {
    StartDate: 'February 5, 2022 00:00:00',
    Team: 'Hull City',
    TeamAbbreviation: 'hcafc',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#ef8901',
    CelebrationMessage: 'Up the tigers!',
    OldestSeason: '2021/2022',
    GameURL: null,
    slug: 'hcafc',
    backgroundColor: '#200c01',
    backgroundColorDark: '#4b310c',
    backgroundColorTranslucent: '#47290070',
    backgroundColorFaint: '#bf750f',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#1f3ad1',
  },
  {
    StartDate: 'February 5, 2022 00:00:00',
    Team: 'Stockport County',
    TeamAbbreviation: 'stock',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#91c7ff',
    CelebrationMessage: 'Up the County!',
    OldestSeason: '2021/2022',
    GameURL: null,
    slug: 'Stock',
    backgroundColor: '#091021',
    backgroundColorDark: '#091021',
    backgroundColorTranslucent: '#0f172a70',
    backgroundColorFaint: '#1a253d',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#1f3ad1',
    socialHashtag: 'StockportCounty',
  },
  {
    StartDate: 'May 5, 2022 00:00:00',
    Team: 'Hartlepool United',
    TeamAbbreviation: 'HUFC',
    TotalTries: 'Total Tries',
    SuccessRate: 'Success Rate',
    CurrentStreak: 'Current Streak',
    BestStreak: 'Best Streak',
    TeamColor: '#91c7ff',
    CelebrationMessage: 'Up the Pools!',
    OldestSeason: '2003/2004',
    GameURL: null,
    slug: 'hufc',
    backgroundColor: '#091021',
    backgroundColorDark: '#091021',
    backgroundColorTranslucent: '#0f172a70',
    backgroundColorFaint: '#1a253d',
    textColor: '#ffffff',
    textColorDark: '#ffffff65',
    correctKeyColor: '#538d4e',
    correctKeyColorDark: '#538d4e50',
    presentKeyColor: '#b59f3b',
    presentKeyColorDark: '#b59f3b50',
    absentKeyColor: '#00000090',
    absentKeyColorDark: '#000000',
    shareButtonColor: '#1f3ad1',
    socialHashtag: 'HUFC',
  },
];

export default availableWordles;
