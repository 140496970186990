import { Seasons, Teams, Positions, PlayerInfoType } from '../../types/player';

const WiganPlayers: PlayerInfoType[] = [
  {
    firstName: 'Jamie',
    secondName: 'Jones',
    team: Teams.FirstTeam,
    position: Positions.Goalkeeper,
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
  },
  {
    firstName: 'Ben',
    secondName: 'Amos',
    team: Teams.FirstTeam,
    position: Positions.Goalkeeper,
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
  },
  {
    firstName: 'Thelo',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Aasgaard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Steven',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Sumner',
    team: Teams.U18s,
  },
  {
    firstName: 'Kelland',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Watts',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Owen',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Goalkeeper,
    secondName: 'Evans',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jason',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Kerr',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kal',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Naismith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Chheyenne',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Dunkley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Burgess',
    team: Teams.U23s,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'McClean',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Magennis',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Emeka',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Obi',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Charlie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Wyke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jonas',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Olsson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dujon',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Sterling',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Goalkeeper,
    secondName: 'Marshall',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Lang',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Naylor',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Max',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Power',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Tickle',
    team: Teams.U23s,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Proctor',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'William',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Grigg',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dan',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Goalkeeper,
    secondName: 'Lavercombe',
    team: Teams.U23s,
  },
  {
    firstName: 'Dean',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Pinnington',
    team: Teams.U23s,
  },
  {
    firstName: 'Jamal',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Lowe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'George',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Johnston',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alex',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Dobre',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Pearce',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Babajide',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Adeeko',
    team: Teams.U23s,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'McHugh',
    team: Teams.U23s,
  },
  {
    firstName: 'Bobby',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Goalkeeper,
    secondName: 'Jones',
    team: Teams.U23s,
  },
  {
    firstName: 'Darron',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Gibson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gwion',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Edwards',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Costello',
    team: Teams.U23s,
  },
  {
    firstName: 'Will',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Keane',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Antonee',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Robinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Graeme',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Shinnie',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Cousins',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bennett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Devante',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Cole',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alex',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Perry',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nick',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Powell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Leon',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Balogun',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Whatmough',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Chris',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Merrie',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Morsy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gavin',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Massey',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Garner',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dan',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Gardner',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Wootton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Carragher',
    team: Teams.U23s,
  },
  {
    firstName: 'Dan',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Burn',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Corey',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Whelan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Long',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tendayi',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Darikwa',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Glen',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Rea',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kieran',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Lloyd',
    team: Teams.U23s,
  },
  {
    firstName: 'Kieran',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Dowell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lee',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Evans',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Divin',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Baningime',
    team: Teams.U23s,
  },
  {
    firstName: 'Christian',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Goalkeeper,
    secondName: 'Walton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Cedric',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Kipre',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Gelhardt',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Smith',
    team: Teams.U23s,
  },
  {
    firstName: 'Joseph',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Dodoo',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Bright',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Enobakhare',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Mlakar',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Fox',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nathan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Byrne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ollie',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Crankshaw',
    team: Teams.U23s,
  },
  {
    firstName: 'Shaun',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'MacDonald',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Timi',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Sobowale',
    team: Teams.U23s,
  },
  {
    firstName: 'Funso',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Ojo',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Walker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matt',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Gilks',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Stubbs',
    team: Teams.U23s,
  },
  {
    firstName: 'Kieffer',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Moore',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Reece',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Burke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stephen',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Humphrys',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Elder',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Viv',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Solomon-Otabor',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mackenzie',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Oneill',
    team: Teams.U23s,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'OBrien',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Yanic',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Wildschut',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Theo',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Goalkeeper,
    secondName: 'Roberts',
    team: Teams.U23s,
  },
  {
    firstName: 'Kyle',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Knoyle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Victor',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Maffeo',
    team: Teams.U23s,
  },
  {
    firstName: 'Craig',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Morgan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Burke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matija',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Goalkeeper,
    secondName: 'Sarkic',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Vaughan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Hanson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kaiyne',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Woolery',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Macleod',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Hendry',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Reece',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'James',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Leon',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Clarke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Connolly',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Flores',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'McGee',
    team: Teams.U23s,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Goalkeeper,
    secondName: 'Bogdan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alex',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Bruce',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jay',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Fulton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'McManaman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Noel',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Hunt',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gary',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Roberts',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jensen',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Weir',
    team: Teams.U23s,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Perkins',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Barrigan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Laurent',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Will',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'McGuffie',
    team: Teams.U23s,
  },
  {
    firstName: 'Jakob',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Goalkeeper,
    secondName: 'Haugaard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alex',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Gilbey',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Terell',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Thomas',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Byrne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Le Fondre',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tyler',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Golden',
    team: Teams.U23s,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Jacobs',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Garbutt',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Bayliss',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Colclough',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordi',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Gomez',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Robinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Reece',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'James',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kyle',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Joseph',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Craig',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Davies',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Omar',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Bogle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Charlie',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Jolley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stephen',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Warnock',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Anthony',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Pilkington',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marcus',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Browne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Windass',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Buxton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Zach',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Clough',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Tunnicliffe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mikael',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Mandron',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Donovan',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Daniels',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gabriel',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Obertan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andy',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Kellett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ivan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Toney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jussi',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Jaaskelainen',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'McGrath',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Taylor',
    team: Teams.FirstTeam,
  },
];

export default WiganPlayers;
