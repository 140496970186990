import React, { ReactElement } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './Routes/Routes';
import './App.css';

const App = (): ReactElement => (
  <HelmetProvider>
    <Routes />
  </HelmetProvider>
);

export default App;
