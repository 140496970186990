import { isMobile } from 'react-device-detect';
import { getGuessStatuses } from './statuses';
import { getWordOfDay } from './wordUtils';
import { AvailableWordlesType } from '../constants/availableWordles';

export const generateEmojiGrid = (
  guesses: string[],
  slug: string,
  theme: AvailableWordlesType
): string =>
  guesses
    .map((guess) => {
      const status = getGuessStatuses(guess, slug, theme);
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩';
            case 'present':
              return '🟨';
            default:
              return '⬛';
          }
        })
        .join('');
    })
    .join('\n')
    .trim();

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  handleShare: () => void,
  slug: string,
  theme: AvailableWordlesType
): void => {
  const hashtag = theme.socialHashtag || theme.TeamAbbreviation.toLowerCase();
  const { solutionIndex } = getWordOfDay(slug, theme);
  const shareString = ` ${theme.TeamAbbreviation.toUpperCase()}LE ${
    solutionIndex + 1
  } ${lost ? 'X' : guesses.length}/6\n\n${generateEmojiGrid(
    guesses,
    slug,
    theme
  )}\n#${hashtag} #${theme.TeamAbbreviation.toLowerCase()}le \n${
    theme.GameURL || window.location.href
  }`;
  if (navigator.share && isMobile) {
    navigator
      .share({
        title: `${theme.TeamAbbreviation.toUpperCase()}LE ${solutionIndex + 1}`,
        text: shareString,
      })
      .then(() => {
        handleShare();
      })
      .catch(() => {
        navigator.clipboard.writeText(shareString);
        handleShare();
      });
  } else {
    navigator.clipboard.writeText(shareString);
    handleShare();
  }
};
