import { Seasons, Teams, Positions, PlayerInfoType } from '../../types/player';

const StockportPlayers: PlayerInfoType[] = [
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Croasdale',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paddy',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Madden',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Millenic',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Alli',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Stott',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Johnson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Schofield',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Kitching',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Whitfield',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Keane',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ethan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Pye',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Holding',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Hinchliffe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Myles',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Hippolyte',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alex',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Reid',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Connor',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Jennings',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Minihan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ash',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Palmer',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ollie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Crankshaw',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Will',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Collar',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Earl',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Antoni',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Sarcevic',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Elliot',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Newby',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Walker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Rydel',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Barclay',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Macauley',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Southam-Hales',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lois',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Maynard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Hogan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Quigley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ethan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Ross',
    team: Teams.FirstTeam,
  },
];
export default StockportPlayers;
