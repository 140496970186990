import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../../App.css';
import Header from '../Header/Header';
import Grid from '../Grid/Grid';
import Keyboard from '../Keyboard/Keyboard';
import InfoModal from '../Modals/InfoModal';
import HintModal from '../Modals/HintModal';
import StatsModal from '../Modals/StatsModal';
import CommonAlert from '../Alerts/Alert';
import {
  isWordInWordList,
  isWinningWord,
  getWordOfDay,
} from '../../utils/wordUtils';
import {
  saveGameStateToLocalStorage,
  loadGameStateFromLocalStorage,
} from '../../utils/localStorage';
import { loadStats, addStatsForCompletedGame } from '../../utils/stats';
import ParamsType from '../../types/params';
import availableWordles, {
  AvailableWordlesType,
} from '../../constants/availableWordles';
import ThemeContext from '../../store/ThemeContext';

const Container = (): ReactElement => {
  const { slug } = useParams<ParamsType>();
  // Redirecting these to the standalone applications as they are both already in progress and I don't want to ruin people's stats :D
  if (slug === 'wafc') {
    window.location.href = 'https://wafcle.com';
  }
  if (slug === 'ltfc') {
    window.location.href = 'https://ltfcle.netlify.app';
  }
  const isSlugValid = availableWordles.find(
    ({ slug: themeSlug }) => themeSlug.toLowerCase() === slug.toLowerCase()
  );
  if (!isSlugValid) {
    return <Redirect to="/" />;
  }
  const [selectedTheme, setSelectedTheme] =
    useState<AvailableWordlesType>(isSlugValid);
  const { solution } = getWordOfDay(slug, selectedTheme);
  const [isGameWon, setIsGameWon] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false);
  const [isGameLost, setIsGameLost] = useState(false);
  const [currentGuess, setCurrentGuess] = useState('');
  const [successAlert, setSuccessAlert] = useState('');
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(selectedTheme);
    if (loaded?.solution !== solution) {
      return [];
    }
    const gameWasWon = loaded.guesses.includes(solution);
    if (gameWasWon) {
      setIsGameWon(true);
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true);
    }
    return loaded.guesses;
  });
  const [stats, setStats] = useState(() => loadStats(selectedTheme));

  useEffect(() => {
    const validWordle = availableWordles.find(
      ({ slug: themeSlug }) => themeSlug.toLowerCase() === slug.toLowerCase()
    );
    if (validWordle) {
      setSelectedTheme(validWordle);
    }
  }, [slug]);

  useEffect(() => {
    const previousGameState = loadGameStateFromLocalStorage(selectedTheme);
    if (
      !previousGameState ||
      (previousGameState && !previousGameState.lastActive)
    ) {
      setIsInfoModalOpen(true);
    }
    const timeNow = String(new Date());
    saveGameStateToLocalStorage(
      {
        guesses,
        solution,
        lastActive: timeNow || '',
      },
      selectedTheme
    );
  }, [guesses]);

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(selectedTheme.CelebrationMessage);
      setTimeout(() => {
        setSuccessAlert('');
        setIsStatsModalOpen(true);
      }, 2000);
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true);
      }, 2000);
    }
  }, [isGameWon, isGameLost]);

  const onChar = (value: string) => {
    if (
      currentGuess.length < solution.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`);
    }
  };

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const onEnter = () => {
    if (
      isGameWon ||
      isGameLost ||
      isHintModalOpen ||
      isInfoModalOpen ||
      isStatsModalOpen
    ) {
      return;
    }
    if (!(currentGuess.length === solution.length)) {
      setIsNotEnoughLetters(true);
      setTimeout(() => {
        setIsNotEnoughLetters(false);
      }, 2000);
      return;
    }

    if (!isWordInWordList(currentGuess, slug)) {
      setIsWordNotFoundAlertOpen(true);
      setTimeout(() => {
        setIsWordNotFoundAlertOpen(false);
      }, 2000);
      return;
    }

    const winningWord = isWinningWord(currentGuess, slug, selectedTheme);

    if (
      currentGuess.length === solution.length &&
      guesses.length < 6 &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess]);
      setCurrentGuess('');

      if (winningWord) {
        if (stats) {
          setStats(
            addStatsForCompletedGame(stats, guesses.length, selectedTheme)
          );
        }
        setIsGameWon(true);
      }

      if (guesses.length === 5) {
        if (stats) {
          setStats(
            addStatsForCompletedGame(stats, guesses.length + 1, selectedTheme)
          );
        }
        setIsGameLost(true);
      }
    }
  };

  return (
    <ThemeContext.Provider value={selectedTheme}>
      <Helmet>
        <title>
          {selectedTheme.TeamAbbreviation.split('')[0]}
          {selectedTheme.TeamAbbreviation.slice(
            1,
            selectedTheme.TeamAbbreviation.length
          ).toLowerCase()}
          le - A daily word guessing game
        </title>
      </Helmet>
      <article
        className="appWrapper"
        style={{
          background: selectedTheme.backgroundColor,
          color: selectedTheme.textColor,
        }}
      >
        <section className="appContainer">
          <Header
            openInfoModal={(state: boolean) => setIsInfoModalOpen(state)}
            openStatsModal={(state: boolean) => setIsStatsModalOpen(state)}
            openHintModal={(state: boolean) => setIsHintModalOpen(state)}
          />
          <Grid
            guesses={guesses}
            currentGuess={currentGuess}
            solutionLength={solution.length}
          />
          <Keyboard
            onChar={onChar}
            onDelete={onDelete}
            onEnter={onEnter}
            guesses={guesses}
          />
        </section>
      </article>
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <HintModal
        isOpen={isHintModalOpen}
        handleClose={() => setIsHintModalOpen(false)}
        numberOfGuesses={guesses.length}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert('Game copied to clipboard');
          return setTimeout(() => setSuccessAlert(''), 2000);
        }}
      />
      <CommonAlert message="Not enough letters" isOpen={isNotEnoughLetters} />
      <CommonAlert
        message="Word not in word list"
        isOpen={isWordNotFoundAlertOpen}
      />
      <CommonAlert
        message={`The word was ${solution}`}
        isOpen={isGameLost}
        variant="error"
      />
      <CommonAlert
        message={successAlert}
        isOpen={successAlert !== ''}
        variant="success"
      />
    </ThemeContext.Provider>
  );
};
export default Container;
