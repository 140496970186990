import { Seasons, Teams, Positions, PlayerInfoType } from '../../types/player';

const LutonPlayers: PlayerInfoType[] = [
  {
    firstName: 'Fred',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Onyedinma',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Oliver',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Lynch',
    team: Teams.U18s,
  },
  {
    firstName: 'Aidan Francis',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Clarke',
    team: Teams.U23s,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Swan',
    team: Teams.U18s,
  },
  {
    firstName: 'Tyrelle',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Newton',
    team: Teams.U23s,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'OdellBature',
    team: Teams.U18s,
  },
  {
    firstName: "Amari'i",
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jed',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Steer',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Wedd',
    team: Teams.U18s,
  },
  {
    firstName: 'Dan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Potts',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sonny',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bradley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tra',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Lucas',
    team: Teams.U23s,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bree',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darcy',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Moffat',
    team: Teams.U18s,
  },
  {
    firstName: 'Ed',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'McJannet',
    team: Teams.U23s,
  },
  {
    firstName: 'Elijay',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Adebayo',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Henri',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Lansbury',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Peter',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Kioso',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Avan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Jones',
    team: Teams.U23s,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Lockyer',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'TQ',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Addy',
    team: Teams.U23s,
  },
  {
    firstName: 'Eddie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Corbit',
    team: Teams.U18s,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Tompkins',
    team: Teams.U23s,
  },
  {
    firstName: 'Rio',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Martucci',
    team: Teams.U18s,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Cornick',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Isted',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kal',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Naismith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Carlos Mendes',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Gomes',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Neufville',
    team: Teams.U23s,
  },
  {
    firstName: 'Jacob',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Cowler',
    team: Teams.U18s,
  },
  {
    firstName: 'Allan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Campbell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Archie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Heron',
    team: Teams.U18s,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Bateson',
    team: Teams.U18s,
  },
  {
    firstName: 'Admiral',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Muskwe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Shea',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Stevens',
    team: Teams.U23s,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Nicolson',
    team: Teams.U23s,
  },
  {
    firstName: 'Cameron',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Jerome',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Simon',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Sluga',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Millar Matthews',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Lewis',
    team: Teams.U18s,
  },
  {
    firstName: 'Oliver',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Hemlin',
    team: Teams.U18s,
  },
  {
    firstName: 'Elliot',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Lee',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Casey',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Pettit',
    team: Teams.U23s,
  },
  {
    firstName: 'Jameson',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Horlick',
    team: Teams.U23s,
  },
  {
    firstName: 'Reece',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Burke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Hylton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matthew',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Moloney',
    team: Teams.U23s,
  },
  {
    firstName: 'Glen',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Rea',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Bentley',
    team: Teams.U18s,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Burger',
    team: Teams.U18s,
  },
  {
    firstName: 'Pelly-Ruddock',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Mpanzu',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Allen',
    team: Teams.U18s,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Peck',
    team: Teams.U23s,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Beckwith',
    team: Teams.U23s,
  },
  {
    firstName: 'Elliot',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Thorpe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Zack',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Nelson',
    team: Teams.U18s,
  },
  {
    firstName: 'Gabriel',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Osho',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Berry',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dion',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Pereira',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Clark',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Corey',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Panter',
    team: Teams.U23s,
  },
  {
    firstName: 'Jacob',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Pinnington',
    team: Teams.U18s,
  },
];
export default LutonPlayers;
