import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import WebIcon from '@mui/icons-material/Web';
import Leagues from '../../types/teams';

// Team badges
import WAFCBadge from '../../assets/badges/wafc.png';
import LTFCBadge from '../../assets/badges/ltfc.png';
import HCAFCBadge from '../../assets/badges/hcafc.png';
import STOCKBadge from '../../assets/badges/stock.png';
import HUFCBadge from '../../assets/badges/hufc.png';

const availableTeams = [
  {
    team: 'Wigan Athletic',
    badge: WAFCBadge,
    slug: 'wafc',
    league: Leagues.LeagueOne,
    externalLink: 'https://wafcle.netlify.app',
  },
  {
    team: 'Luton Town',
    badge: LTFCBadge,
    slug: 'ltfc',
    league: Leagues.Championship,
    externalLink: 'https://ltfcle.netlify.app',
  },
  {
    team: 'Hull City',
    badge: HCAFCBadge,
    slug: 'hcafc',
    league: Leagues.Championship,
    externalLink: null,
  },
  {
    team: 'Stockport County',
    badge: STOCKBadge,
    slug: 'stock',
    league: Leagues.NonLeague,
    externalLink: null,
  },
  {
    team: 'Hartlepool United',
    badge: HUFCBadge,
    slug: 'hufc',
    league: Leagues.LeagueTwo,
    externalLink: null,
  },
];

const HomepageContainer = styled.article`
  font-family: 'Open Sans', sans-serif;
  background: #0f172a;
  height: 100vh;
  color: #ffffff;
  text-align: center;
  header {
    text-align: center;
    padding: 0.5em 1em;
    border-bottom: solid 0.5px #1a253d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    h1 {
      padding: 0.1em;
      @media (max-width: 768px) {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
`;

const TeamWordle = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1em;
`;

const Team = styled(Link)`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border: solid 0.5px #ffffff40;
  color: #ffffff;
  padding: 0.5em;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 15em;
  p {
    margin: 0.3em;
  }
  img {
    width: 40%;
  }
  &:hover {
    box-shadow: 0px 0px 10px #ffffff;
    background: #1a2744;
  }
`;

const ExternalApp = styled.a`
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border: solid 0.5px #ffffff40;
  color: #ffffff;
  padding: 0.5em;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  width: 15em;
  p {
    margin: 0.3em;
  }
  img {
    width: 40%;
  }
  &:hover {
    box-shadow: 0px 0px 10px #ffffff;
    background: #1a2744;
  }
`;

const SocialSection = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
  svg {
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Homepage = (): ReactElement => (
  <HomepageContainer>
    <Helmet>
      <title>Football Wordles - Homepage</title>
    </Helmet>
    <header>
      <SocialSection>
        <TwitterIcon
          onClick={() => {
            window.open('https://twitter.com/NathanAvie', '_blank');
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              window.open('https://twitter.com/NathanAvie', '_blank');
            }
          }}
        />
        <GitHubIcon
          onClick={() => {
            window.open('https://github.com/Nathanavie', '_blank');
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              window.open('https://github.com/Nathanavie', '_blank');
            }
          }}
        />
        <WebIcon
          onClick={() => {
            window.open('https://nathanatherton.com', '_blank');
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              window.open('https://nathanatherton.com', '_blank');
            }
          }}
        />
      </SocialSection>
      <h1>Football Club Wordles</h1>
    </header>
    <section>
      <h2>Select your team</h2>
      <TeamWordle>
        {availableTeams.map(({ team, slug, badge, externalLink }) => {
          if (externalLink) {
            return (
              <ExternalApp href={externalLink}>
                <img src={badge} alt={team} />
              </ExternalApp>
            );
          }
          return (
            <Team to={slug}>
              <img src={badge} alt={team} />
            </Team>
          );
        })}
      </TeamWordle>
    </section>
  </HomepageContainer>
);
export default Homepage;
