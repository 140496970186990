import { getWordOfDay } from './wordUtils';
import { AvailableWordlesType } from '../constants/availableWordles';

export type CharStatus = 'absent' | 'present' | 'correct';

export type CharValue =
  | 'Q'
  | 'W'
  | 'E'
  | 'R'
  | 'T'
  | 'Y'
  | 'U'
  | 'I'
  | 'O'
  | 'P'
  | 'A'
  | 'S'
  | 'D'
  | 'F'
  | 'G'
  | 'H'
  | 'J'
  | 'K'
  | 'L'
  | 'Z'
  | 'X'
  | 'C'
  | 'V'
  | 'B'
  | 'N'
  | 'M';

export const getStatuses = (
  guesses: string[],
  slug: string,
  theme: AvailableWordlesType
): { [key: string]: CharStatus } => {
  const charObj: { [key: string]: CharStatus } = {};
  const { solution } = getWordOfDay(slug, theme);

  guesses.forEach((word) => {
    word.split('').forEach((letter, i) => {
      if (!solution.includes(letter)) {
        // make status absent
        charObj[letter] = 'absent';
        return;
      }

      if (letter === solution[i]) {
        // make status correct
        charObj[letter] = 'correct';
        return;
      }

      if (charObj[letter] !== 'correct') {
        // make status present
        charObj[letter] = 'present';
      }
    });
  });

  return charObj;
};

export const getGuessStatuses = (
  guess: string,
  slug: string,
  theme: AvailableWordlesType
): CharStatus[] => {
  const { solution } = getWordOfDay(slug, theme);
  const splitSolution = solution.split('');
  const splitGuess = guess.split('');

  const solutionCharsTaken = splitSolution.map(() => false);

  const statuses: CharStatus[] = Array.from(Array(guess.length));

  // handle all correct cases first
  splitGuess.forEach((letter, i) => {
    if (letter === splitSolution[i]) {
      statuses[i] = 'correct';
      solutionCharsTaken[i] = true;
    }
  });

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return;

    if (!splitSolution.includes(letter)) {
      // handles the absent case
      statuses[i] = 'absent';
      return;
    }

    // now we are left with "present"s
    const indexOfPresentChar = splitSolution.findIndex(
      (x: string, index: number) => x === letter && !solutionCharsTaken[index]
    );

    if (indexOfPresentChar > -1) {
      statuses[i] = 'present';
      solutionCharsTaken[indexOfPresentChar] = true;
    } else {
      statuses[i] = 'absent';
    }
  });

  return statuses;
};
