import { Seasons, Teams, Positions, PlayerInfoType } from '../../types/player';

const HartlepoolPlayers: PlayerInfoType[] = [
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Compton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dan',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nathan',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Thomas',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kieran',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Green',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Heardman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Connor',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Smith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Cook',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Aaron',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Cunningham',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Claudio',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Ofosu',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Alessandra',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tyler',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Magloire',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Omar',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Bogle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Nelson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Cooper',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jonny',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Goalkeeper,
    secondName: 'Maxted',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Fryer',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Magnus',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Okuonghae',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Bradley',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Fewster',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Donaldson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Crawford',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gavan',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Holohan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marcus',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Carver',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Forward,
    secondName: 'Grey',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Hugh',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Defender,
    secondName: 'Robertson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paul',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Defender,
    secondName: 'Johnson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Barmby',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Blackford',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Neil',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Austin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matt',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Dolan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nathan',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Defender,
    secondName: 'Buddle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Defender,
    secondName: 'Baldwin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Peter',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Goalkeeper,
    secondName: 'Denton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Killip',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andy',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Goalkeeper,
    secondName: 'Rafferty',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matty',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Defender,
    secondName: 'Robson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jim',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Goalkeeper,
    secondName: 'Provett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Tinkler',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Henderson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harly',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Wise',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Hendrie',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nathan',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Midfielder,
    secondName: 'Luscombe',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darren',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Defender,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matt',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Crooks',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brook',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Miller',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ritchie',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Midfielder,
    secondName: 'Humphreys',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stuart',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Parnaby',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darryl',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Forward,
    secondName: 'Duffy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Eifion',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Goalkeeper,
    secondName: 'McHugh',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Forward,
    secondName: 'Craddock',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Micky',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Barron',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Thorne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Mirfin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Rhys',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Oates',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Laurent',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paul',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Arnison',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jason',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Midfielder,
    secondName: 'Banton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Pollock',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Goalkeeper,
    secondName: 'Catterick',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Defender,
    secondName: 'Rowbotham',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marco',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Forward,
    secondName: 'Gabbiadini',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Midfielder,
    secondName: 'Gamble',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dean',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'McDonald',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Leon',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Defender,
    secondName: 'McSweeney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jonathan',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Midfielder,
    secondName: 'Rowell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Christian',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Defender,
    secondName: 'Burgess',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Orrell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Rune',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Lange',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Midfielder,
    secondName: 'McCann',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Defender,
    secondName: 'Clark',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marvin',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Morgan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Zak',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Boagey',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Defender,
    secondName: 'McCunnie',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tyler',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Hamilton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gus',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Mafuta',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Rakish',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Forward,
    secondName: 'Bingham',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Conor',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Goalkeeper,
    secondName: 'Devlin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Padraig',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Amond',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Aidan',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Keena',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Steve',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Defender,
    secondName: 'Howey',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Arran',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Goalkeeper,
    secondName: 'Lee-Barrett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Boyce',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Timi',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Odusina',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Carl',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Magnay',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Zaine',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Francis-Angol',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paul',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Forward,
    secondName: 'Robinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Goalkeeper,
    secondName: 'Flinders',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Goalkeeper,
    secondName: 'Mooney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Neill',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Defender,
    secondName: 'Collins',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Midfielder,
    secondName: 'Gobern',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kevin',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Forward,
    secondName: 'Henderson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alan',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Midfielder,
    secondName: 'Pouton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kenton',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Richardson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mason',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Bloomfield',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Fraser',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Kerr',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gime',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Toure',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joel',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Goalkeeper,
    secondName: 'Dixon',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Armann',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Defender,
    secondName: 'Bjornsson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brad',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Goalkeeper,
    secondName: 'James',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'John',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Defender,
    secondName: 'Brackstone',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marcus',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Dinanga',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'Cassidy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Patrick',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Boyes',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Chris',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Defender,
    secondName: 'Westwood',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Campbell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Peter',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Defender,
    secondName: 'Hartley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Isaac',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Fletcher',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Elliott',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Goalkeeper,
    secondName: 'Foden',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Forward,
    secondName: 'Rae',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Defender,
    secondName: 'Mellish',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jamie',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Sterry',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Ironside',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ebby',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Midfielder,
    secondName: 'Nelson-Addy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Goalkeeper,
    secondName: 'Kean',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Daniel',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Nardiello',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'George',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brad',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Halliday',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Ferguson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Samuel',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Midfielder,
    secondName: 'Adjei',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Martin',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Smith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gary',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Liddle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Harrison',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Keigan',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Parker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Antony',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Sweeney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Connor',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Oliver',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Conor',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Newton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Goalkeeper,
    secondName: 'Allison',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Neill',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Byrne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Ledger',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tommy',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Miller',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Nearney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Coles',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Parkhouse',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jonathan',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Franks',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Bunney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Carroll',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lee',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Midfielder,
    secondName: 'Bullock',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Freddie',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Goalkeeper,
    secondName: 'Woodman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Bird',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Greg',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Forward,
    secondName: 'Rutherford',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Evan',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Defender,
    secondName: 'Horwood',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stephen',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Midfielder,
    secondName: 'Turnbull',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Forward,
    secondName: 'Poole',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Robbie',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Elliott',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Orrell',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Rob',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paul',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Midfielder,
    secondName: 'Murray',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Johnson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Pritchard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harry',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Worley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Jackson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lee',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Goalkeeper,
    secondName: 'Nicholls',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Toto',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Nsiala',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Defender,
    secondName: 'Ross',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Aaron',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Tshibola',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Chris',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'Llewellyn',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Devante',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Rodney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Callum',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Forward,
    secondName: 'Hassan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Guy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jan',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Goalkeeper,
    secondName: 'Budtz',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Davies',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andy',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Monkhouse',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brad',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Goalkeeper,
    secondName: 'Young',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'James',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nicky',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Featherstone',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Macauley',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Southam-Hales',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Brobbel',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Martin',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Midfielder,
    secondName: 'Young',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stephen',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Midfielder,
    secondName: 'Carson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Defender,
    secondName: 'Skarz',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jacob',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Owen',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Richie',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Bennett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jon',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Bass',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ian',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Forward,
    secondName: 'Thomas-Moore',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Rob',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Harker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Simon',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Midfielder,
    secondName: 'Walton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Billy',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Paynter',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darrell',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'Clarke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Defender,
    secondName: 'Tait',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sidney',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Midfielder,
    secondName: 'Schmeltz',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ali',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Midfielder,
    secondName: 'Gibb',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tyrone',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Forward,
    secondName: 'ONeill',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dimitrios',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Goalkeeper,
    secondName: 'Konstantopoulos',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tony',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Goalkeeper,
    secondName: 'Caig',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Paddy',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'McLaughlin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marlon',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Harewood',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Hawkins',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'White',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alan',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Midfielder,
    secondName: 'Thompson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Richie',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Barker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Atkinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Henrich',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Goalkeeper,
    secondName: 'Ravas',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Armstrong',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Hugill',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Butler',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Peter',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Kioso',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Marcus',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Forward,
    secondName: 'Richardson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joe',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'White',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nick',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Goalkeeper,
    secondName: 'Thomas',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Phil',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Turnbull',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Carl',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Munns',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Donnelly',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Forward,
    secondName: 'Walker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Duckworth',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Goalkeeper,
    secondName: 'Loach',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nolberto',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Midfielder,
    secondName: 'Solano',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Fabian',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Midfielder,
    secondName: 'Yantorno',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jordan',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Richards',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'MacDonald',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kal',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Defender,
    secondName: 'Naismith',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andrew',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Midfielder,
    secondName: 'Appleby',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Alan',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Midfielder,
    secondName: 'Power',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Niko',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Muir',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Scott',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Midfielder,
    secondName: 'Woods',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dylan',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Defender,
    secondName: 'Purvis',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Louis',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Forward,
    secondName: 'Rooney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Martin',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Midfielder,
    secondName: 'Woods',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ben',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Dudzinski',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nicholas',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Goalkeeper,
    secondName: 'Bilokapic',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tomi',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Adeloye',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Raynes',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mikael',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Forward,
    secondName: 'Mandron',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ellis',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Forward,
    secondName: 'Harrison',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ritchie',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Midfielder,
    secondName: 'Jones',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jon',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Forward,
    secondName: 'Daly',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Willie',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Midfielder,
    secondName: 'Boland',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Lewis',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Defender,
    secondName: 'Cass',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Hull',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darren',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Defender,
    secondName: 'Craddock',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Reagan',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Defender,
    secondName: 'Ogle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sean',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Kavanagh',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Steve',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'Istead',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Forward,
    secondName: 'Fenwick',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kudus',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Forward,
    secondName: 'Oyenuga',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Forward,
    secondName: 'Brown',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Tom',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Midfielder,
    secondName: 'Haigh',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matthew',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Bates',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gavin',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Strachan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Brad',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Midfielder,
    secondName: 'Walker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Thomas',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Midfielder,
    secondName: 'Butler',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Charlie',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Forward,
    secondName: 'Wyke',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Anthony',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Goalkeeper,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matteo',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Lanzoni',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Sam',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Collins',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nicke',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Forward,
    secondName: 'Kabamba',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Graeme',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Lee',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jason',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Kennedy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Connor',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Forward,
    secondName: 'Simpson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jermaine',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Forward,
    secondName: 'Easter',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Shelton',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Forward,
    secondName: 'Foley',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Zach',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Goalkeeper,
    secondName: 'Hemming',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Maidens',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Goalkeeper,
    secondName: 'Cook',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Neil',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Midfielder,
    secondName: 'Danns',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Gerard',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Defender,
    secondName: 'Nash',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jack',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Midfielder,
    secondName: 'Wilkinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nialle',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Forward,
    secondName: 'Rodney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Matty',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Forward,
    secondName: 'Tymon',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Bartlett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Ryan',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Forward,
    secondName: 'Noble',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Molyneux',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Andy',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Jordan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luke',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Midfielder,
    secondName: 'Williams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Colin',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Forward,
    secondName: 'Nish',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Myles',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Anderson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Robinson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Billy',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Forward,
    secondName: 'Greulich',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Bale',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Scott',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Walker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Craig',
    mostRecentYear: Seasons.TwelveThirteen,
    position: Positions.Midfielder,
    secondName: 'Lynch',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Louis',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Laing',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Roy',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Forward,
    secondName: 'ODonovan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Craig',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Midfielder,
    secondName: 'Hignett',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kevin',
    mostRecentYear: Seasons.FourFive,
    position: Positions.Midfielder,
    secondName: 'Betsy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Stephen',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Defender,
    secondName: 'Wright',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mark',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Kitching',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Mitchell',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Goalkeeper,
    secondName: 'Beeney',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'David',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Edgar',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Coleby',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Shepherd',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Trevor',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Goalkeeper,
    secondName: 'Carson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Forward,
    secondName: 'Mackay',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Defender,
    secondName: 'Byrne',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harvey',
    mostRecentYear: Seasons.TwentyTwentyTwentyOne,
    position: Positions.Forward,
    secondName: 'Saunders',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Danny',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Amos',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jon-Paul',
    mostRecentYear: Seasons.FiveSix,
    position: Positions.Forward,
    secondName: 'Pittman',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Godwin',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Antwi',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Dennis',
    mostRecentYear: Seasons.TenEleven,
    position: Positions.Forward,
    secondName: 'Behan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Blair',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Adams',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Josh',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Hawkes',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Harvey',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Rodgers',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Defender,
    secondName: 'Nelson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Michael',
    mostRecentYear: Seasons.SixSeven,
    position: Positions.Forward,
    secondName: 'Proctor',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Chris',
    mostRecentYear: Seasons.ThreeFour,
    position: Positions.Midfielder,
    secondName: 'Shuker',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Steve',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Defender,
    secondName: 'Haslam',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Joel',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Porter',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Kevin',
    mostRecentYear: Seasons.EightNine,
    position: Positions.Forward,
    secondName: 'Kyle',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Darren',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Defender,
    secondName: 'Holden',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Jake',
    mostRecentYear: Seasons.FifteenSixteen,
    position: Positions.Midfielder,
    secondName: 'Gray',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Bryn',
    mostRecentYear: Seasons.TwentyOneTwentyTwo,
    position: Positions.Midfielder,
    secondName: 'Morris',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Keith',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Defender,
    secondName: 'Watson',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Liam',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Midfielder,
    secondName: 'Noble',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Julian',
    mostRecentYear: Seasons.NineTen,
    position: Positions.Defender,
    secondName: 'Cherel',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Nicky',
    mostRecentYear: Seasons.SeventeenEighteen,
    position: Positions.Midfielder,
    secondName: 'Deverdics',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Steve',
    mostRecentYear: Seasons.ThirteenFourteen,
    position: Positions.Forward,
    secondName: 'Howard',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Colin',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Forward,
    secondName: 'Larkin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'James',
    mostRecentYear: Seasons.SixteenSeventeen,
    position: Positions.Defender,
    secondName: 'Martin',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Luca',
    mostRecentYear: Seasons.EighteenNineteen,
    position: Positions.Defender,
    secondName: 'Murphy',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Romoney',
    mostRecentYear: Seasons.NineteenTwenty,
    position: Positions.Defender,
    secondName: 'Crichlow',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Adam',
    mostRecentYear: Seasons.ElevenTwelve,
    position: Positions.Forward,
    secondName: 'Boyd',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Eddie',
    mostRecentYear: Seasons.SevenEight,
    position: Positions.Defender,
    secondName: 'Nolan',
    team: Teams.FirstTeam,
  },
  {
    firstName: 'Johnny',
    mostRecentYear: Seasons.FourteenFifteen,
    position: Positions.Goalkeeper,
    secondName: 'Barber',
    team: Teams.FirstTeam,
  },
];

export default HartlepoolPlayers;
