import { PlayerInfoType } from '../types/player';
import WiganPlayers from './TeamsPlayers/wafc';
import LutonPlayers from './TeamsPlayers/ltfc';
import HullPlayers from './TeamsPlayers/hcafc';
import StockportPlayers from './TeamsPlayers/stock';
import HartlepoolPlayers from './TeamsPlayers/hartlepool';

interface AllPlayers {
  wafc: PlayerInfoType[];
  ltfc: PlayerInfoType[];
  hcafc: PlayerInfoType[];
  stock: PlayerInfoType[];
  hufc: PlayerInfoType[];
}

const players: AllPlayers = {
  wafc: WiganPlayers,
  ltfc: LutonPlayers,
  hcafc: HullPlayers,
  stock: StockportPlayers,
  hufc: HartlepoolPlayers,
};
export default players;
