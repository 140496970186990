import React, { ReactElement, useContext, useState } from 'react';
import Countdown from 'react-countdown';
import { useParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import { GameStats } from '../../utils/localStorage';
import { getWordOfDay } from '../../utils/wordUtils';
import { shareStatus } from '../../utils/share';
import StatBar from '../Stats/StatBar';
import Histogram from '../Stats/Histogram';
import ParamsType from '../../types/params';
import ThemeContext from '../../store/ThemeContext';

interface StatsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  guesses: string[];
  gameStats: GameStats | null;
  isGameLost: boolean;
  isGameWon: boolean;
  handleShare: () => void;
}

const StatsModal = ({
  isOpen,
  handleClose,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShare,
}: StatsModalProps): ReactElement => {
  const theme = useContext(ThemeContext);
  const { slug } = useParams<ParamsType>();
  const { solution, solutionIndex, tomorrow } = getWordOfDay(slug, theme);
  const [buttonHover, setButtonHover] = useState(false);

  if (gameStats && gameStats.totalGames <= 0) {
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          background: theme.backgroundColor,
          color: theme.textColor,
        }}
      >
        <header className="modalHeader">
          <h2>Statistics</h2>
          <CloseIcon role="button" onClick={handleClose} />
        </header>
        <Typography>
          <StatBar gameStats={gameStats} theme={theme} />
        </Typography>
      </Box>
    </Modal>;
  }
  return (
    <Modal className="modal" open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          background: theme.backgroundColor,
          color: theme.textColor,
        }}
      >
        <header className="modalHeader">
          <h2>Statistics</h2>
          <CloseIcon role="button" onClick={handleClose} />
        </header>
        <Typography>
          {(isGameLost || isGameWon) && (
            <h4 className="bold center">
              {theme.TeamAbbreviation.toUpperCase()}LE {solutionIndex + 1}{' '}
              answer: {solution}
            </h4>
          )}
          <StatBar gameStats={gameStats} theme={theme} />
          <h4>Guess Distribution</h4>
          <Histogram gameStats={gameStats} />
        </Typography>
        {(isGameLost || isGameWon) && (
          <section className="modalFooter">
            <section className="newWord">
              <h5>
                NEXT{' '}
                <span style={{ color: theme.TeamColor }}>
                  #{theme.TeamAbbreviation.toUpperCase()}
                </span>
                LE
              </h5>
              <Countdown className="countdown" date={tomorrow} daysInHours />
            </section>
            <Button
              type="button"
              onClick={() => {
                shareStatus(guesses, isGameLost, handleShare, slug, theme);
              }}
              endIcon={<ShareIcon />}
              onMouseEnter={() => {
                setButtonHover(true);
              }}
              onMouseLeave={() => {
                setButtonHover(false);
              }}
              style={{
                background: buttonHover
                  ? theme.backgroundColorFaint
                  : theme.TeamColor,
                color: theme.textColor,
              }}
            >
              Share
            </Button>
          </section>
        )}
      </Box>
    </Modal>
  );
};
export default StatsModal;
