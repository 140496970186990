import players from '../constants/players';
import { PlayerInfoType } from '../types/player';
import { AvailableWordlesType } from '../constants/availableWordles';

export const isWordInWordList = (word: string, slug: string): boolean =>
  players[slug as keyof typeof players].some(
    ({ secondName }) => secondName.toLowerCase() === word.toLowerCase()
  );
// TODO: Add these when adding optional normal words
// import { acceptedWords } from '../constants/standardWordsList';
// import { originalWordleWords } from '../constants/wordleWordList';
// acceptedWords.some(
//   (validWord) => validWord.toLowerCase() === word.toLowerCase()
// ) ||
// originalWordleWords.some(
//   (validWord) => validWord.toLowerCase() === word.toLowerCase()
// );

export const getWordOfDay = (
  slug: string,
  theme: AvailableWordlesType
): {
  solution: string;
  solutionIndex: number;
  tomorrow: number;
  solutionInfo: PlayerInfoType;
} => {
  const selectedPlayers = players[slug as keyof typeof players];
  // January 1, 2022 Game Epoch
  const epochMs = new Date(theme.StartDate).valueOf();
  const now = Date.now();
  const msInDay = 86400000;
  const index = Math.floor((now - epochMs) / msInDay);
  const nextday = (index + 1) * msInDay + epochMs;

  return {
    solution:
      selectedPlayers[index % selectedPlayers.length].secondName.toUpperCase(),
    solutionIndex: index,
    tomorrow: nextday,
    solutionInfo: selectedPlayers[index % selectedPlayers.length],
  };
};

export const isWinningWord = (
  word: string,
  slug: string,
  theme: AvailableWordlesType
): boolean =>
  getWordOfDay(slug, theme).solution.toLowerCase() === word.toLowerCase();
